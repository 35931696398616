import React from 'react';
import { bool, func, string } from 'prop-types';
import loadable from '@loadable/component';

import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Image,
  Text,
} from '@chakra-ui/react';
import txt from './locales';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import { IMG_LOGO_INITIAL, BG_PHOTO_CONTENT, IMG_PATTERN_LEFT } from '@/constants/assets';
import { BUTTON_PROPS, DRAWER_COLOR } from '@/constants/colors';

import { photos as photos2 } from './api/photos-2';

function PhotoContent({ lang, isOpen, onClose }) {
  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" {...BUTTON_PROPS} />
        <DrawerBody
          width="100%"
          padding="0"
          bgColor="drawerColor"
          background={`linear-gradient(180deg, ${DRAWER_COLOR} 65%, rgb(255,255,255) 200%)`}
        >
          <Box
            bgImage={`url(${BG_PHOTO_CONTENT})`}
            height="300px"
            bgSize="cover"
            bgPosition="center"
          >
            <Center paddingTop="24px">
              <Image src={IMG_LOGO_INITIAL} maxWidth="60px" />
            </Center>
          </Box>
          {/* Images Cover */}
          <Box
            padding="32px 24px"
            bgImage={`url(${IMG_PATTERN_LEFT})`}
            bgRepeat="repeat-y"
            bgSize="80%"
            textAlign="center"
            color="mainColorText"
          >
            <Text margin="24px 0" fontFamily="heading">
             {`"Yang fana adalah waktu, kita abadi"`}<br /> - Sapardi Djoko Damono, Perahu Kertas
            </Text>
            {/* 2 Section */}
            <Box padding="24px">
              {/* <Heading
                marginBottom="24px"
                fontFamily="headingAlternative"
                size="2xl"
                fontWeight="normal"
              >
                The Engagement
              </Heading> */}
              {isOpen && <LoadableGallerry data={photos2} />}
            </Box>
          </Box>
          <Center paddingBottom="62px">
            <Button
              size="md"
              fontWeight="light"
              color="mainColorText"
              bgColor="alternativeDark"
              onClick={() => onClose()}
              {...BUTTON_PROPS}
            >
              {txt.back[lang]}
            </Button>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  lang: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
