import React from 'react';
import { object, string, bool } from 'prop-types';

import { Box, Center, Heading, Text, AspectRatio, Image } from '@chakra-ui/react';
import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';
import DividerComponent from '@components/Common/Divider';

import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';

function DetailCouple(props) {
  const {
    lang,
    imgUrl,
    isTop,
    instagramId,
    parentName,
    fullName,
    shortName,
    shortNameProps,
    instagramIdProps,
    fullNameProps,
    imgUrlProps,
    parentNameProps,
    ...rest
  } = props;

  return (
    <Box padding="24px" {...rest}>
      {isTop && (
        <WithAnimation>
          <Text
            margin="-42px 0 52px 0"
            textAlign="center"
            fontSize="2xl"
            fontFamily="heading"
            color="mainColorText"
            textTransform="uppercase"
          >
            {txt.bride[lang]}
          </Text>
        </WithAnimation>
      )}
      <WithAnimation left>
        <Center maxHeight="400px">
          <AspectRatio width="100%" maxW="250px" ratio={3 / 4}>
            <Box
              bgImage={`url(${IMG_PATTERN_SQUARE})`}
              bgSize="contain"
              border="4px solid"
              borderColor="bgSecondary"
              borderRadius="16px"
              padding="24px"
            >
              <Image
                src={imgUrl}
                objectPosition="center"
                borderRadius="16px"
                margin="0 auto"
                {...imgUrlProps}
              />
            </Box>
          </AspectRatio>
        </Center>
      </WithAnimation>
      <WithAnimation>
        <Heading
          marginTop="24px"
          color="mainColorText"
          textAlign="center"
          fontSize="6xl"
          fontWeight="normal"
          {...shortNameProps}
        >
          {shortName}
        </Heading>
      </WithAnimation>
      <WithAnimation left>
        <DividerComponent />
      </WithAnimation>
      <WithAnimation>
        <Text
          color="mainColorText"
          fontSize="lg"
          textAlign="center"
          margin="4px 0 24px 0"
          fontFamily="bodyAlternative"
          {...fullNameProps}
        >
          {fullName}
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Text
          color="mainColorText"
          textAlign="center"
          fontSize="sm"
          fontFamily="bodyAlternative"
          dangerouslySetInnerHTML={{ __html: parentName }}
          {...parentNameProps}
        />
      </WithAnimation>
      {instagramId && false && (
        <Center marginTop="24px">
          <WithAnimation>
            <InstagramButton
              id={instagramId}
              text={instagramId}
              color="alternativeDark"
              onlyIcon={false}
              size="sm"
              fontFamily="body"
              fontWeight="normal"
              bgColor="bgSecondary"
              boxShadow="xl"
              {...BUTTON_PROPS}
              {...instagramIdProps}
            />
          </WithAnimation>
        </Center>
      )}
    </Box>
  );
}

DetailCouple.propTypes = {
  lang: string.isRequired,
  isTop: bool.isRequired,
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  parentName: string.isRequired,
  fullName: string.isRequired,
  shortName: string.isRequired,
  shortNameProps: object,
  instagramIdProps: object,
  fullNameProps: object,
  imgUrlProps: object,
  parentNameProps: object,
};

DetailCouple.defaultProps = {
  shortNameProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  imgUrlProps: {},
  parentNameProps: {},
};

export default DetailCouple;
