import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Yth.', 'Dear'),
  user: get('Bapak/Ibu/Saudara/i', 'Family & Friends,'),
  invitationMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `With the blessing of Allah SWT, we would like to invite you to our wedding ceremony:`,
  ),
  announcementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta'ala, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `With the blessing of Allah SWT, we would like to announce our wedding ceremony:`,
  ),
  invitation: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `With the blessing of God, we would like to invite you to our wedding ceremony:`,
  ),
  announcemnt: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `With the blessing of God, we would like to announce our wedding ceremony:`,
  ),
  dan: get('dan', 'and'),
  nameayat: get('[Matius 19:6]', '[Matthew 19:6]'),
  ayat: get("Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”", 
  "So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate.”"),

  nameayatquran: get('[QS. Ar-Rum: 21]', '[QS. Ar-Rum: 21]'),
  ayatquran: get("Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.",
   "And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect."),
};
