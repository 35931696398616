import get from '@invitato/helpers/dist/getLocale';

export default {
  seeLocation: get('Lihat Lokasi', 'See Location'),
  remindMe: get('Ingatkan Acara', 'Remind Me'),
  back: get('Kembali', 'Back'),
  acara: get('Detail Acara', 'The Vows Detail'),
  Akad: get('Akad Nikah', 'Holy Matrimony'),
  Resepsi: get('Resepsi', 'Wedding Reception'),
  detailAkad: get('AKAD NIKAH AKAN DILAKSANAKAN SECARA TERBATAS DAN HANYA DIHADIRI OLEH KELUARGA DAN KERABAT DEKAT PADA:', 'DUE TO RESTRICTIONS ON GATHERING SIZES, THE HOLY MATRIMONY WILL BE HELD WITH A LIMITED CAPACITY ON THE:'),
  resepsiDetai: get('KAMI MOHON KEHADIRAN BAPAK/IBU/SAUDARA/I PADA RESEPSI PERNIKAHAN YANG AKAN DILAKSANAKAN PADA:' , 'join us in OUR celebration at the WEDDING reception on the:')
};
