import React from 'react';
import { string } from 'prop-types';
import { Box } from '@chakra-ui/react';

import DetailCouple from './DetailCard';

import {
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import useLang from '@hooks/useLang';

const backgroundTop = {
  backgroundPosition: 'top',
  isTop: true,
};

const backgroundBottom = {
  isTop: false,
  backgroundPosition: 'bottom',
};

/**
 * Render couple info
 * @param {*} props
 * @returns
 */
function CoupleInfo({ ...rest }) {
  const lang = useLang();
  const renderBoy = (props = {}) => {
    return (
      <DetailCouple
        lang={lang}
        fullName={BOY_NAME}
        shortName={BOY_NAME_SHORT}
        parentName={BOY_PARENT_NAME}
        instagramId={IG_BOY}
        imgUrl={IMG_MAN}
        {...props}
      />
    );
  };

  const renderGirl = (props = {}) => {
    return (
      <DetailCouple
        lang={lang}
        fullName={GIRL_NAME}
        shortName={GIRL_NAME_SHORT}
        parentName={GIRL_PARENT_NAME}
        instagramId={IG_GIRL}
        imgUrl={IMG_GIRL}
        {...props}
      />
    );
  };

  return (
    <Box bgColor="bgPrimary" padding="62px 0 42px 0" {...rest}>
      {IS_BOY_FIRST ? renderBoy(backgroundTop) : renderGirl(backgroundTop)}
      {IS_BOY_FIRST ? renderGirl(backgroundBottom) : renderBoy(backgroundBottom)}
    </Box>
  );
}

CoupleInfo.propTypes = {
  lang: string,
};

export default React.memo(CoupleInfo);
