import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Text, Center, Heading } from '@chakra-ui/react';
import WithAnimation from '@/components/Common/WithAnimation';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import Image from '@/components/Common/LazyImage';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import useInvitation from '@hooks/useInvitation';
import useIsDesktopView from '@hooks/useIsDesktopView';

import { GIRL_NAME_SHORT, BOY_NAME_SHORT, IS_BOY_FIRST } from '@/constants';
import { TEXT_SHADOW } from '@/constants/colors';
import { IMG_PATTERN, IMG_LOGO } from '@/constants/assets';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const isInvitation = useInvitation();
  const { isDesktopView } = useIsDesktopView();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box
      minHeight="100vh"
      bgColor="bgPrimary"
      bgImage={`url(${IMG_PATTERN})`}
      bgSize="100%"
      backgroundRepeat="repeat"
      paddingTop="42px"
    >
      <Center width="100%" zIndex="2" height="85vh">
        <Box padding="0" width="70%" minHeight="460px">
          {showTitle && (
            <Box
              color="secondaryColorText"
              letterSpacing="1px"
              fontWeight="400"
              fontSize="10px"
              width="100%"
              maxW="500px"
              padding="24px"
              bgSize="cover"
              bgColor="bgSecondary"
              boxShadow="2xl"
              borderRadius="42px"
            >
              <WithAnimation>
                <Center>
                  <Image height="160px" src={IMG_LOGO} />
                </Center>
              </WithAnimation>
              <Box textAlign="center" color="alternativeColorText">
                <WithAnimation>
                  <Text fontSize="sm">THE WEDDING OF</Text>
                </WithAnimation>
                {/* BRIDE SECTION */}
                <Box>
                  <WithAnimation>
                    <Heading
                      fontSize="6xl"
                      letterSpacing="2px"
                      textShadow={`1px 1px 2px ${TEXT_SHADOW}`}
                    >
                      {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT}
                    </Heading>
                  </WithAnimation>
                  <WithAnimation>
                    <Heading
                      margin="-8px 0"
                      size="4xl"
                      textShadow={`1px 1px 2px ${TEXT_SHADOW}`}
                    >{`&`}</Heading>
                  </WithAnimation>
                  <WithAnimation>
                    <Heading
                      fontSize="6xl"
                      letterSpacing="2px"
                      textShadow={`1px 1px 2px ${TEXT_SHADOW}`}
                    >
                      {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
                    </Heading>
                  </WithAnimation>
                </Box>
                <WithAnimation>
                  <Text
                    textAlign="center"
                    fontSize="sm"
                    color="alternativeColorText"
                    textTransform="uppercase"
                    margin="8px 0"
                  >
                    {isInvitation ? `Invitation` : `Announcement`}
                  </Text>
                </WithAnimation>
              </Box>
              <WithAnimation>
                <ScrollToDown
                  loaded={loaded}
                  loading={loading}
                  marginTop="16px"
                  padding="8px 32px"
                  text="Open"
                  bgColor="alternativeDark"
                  color="mainColorText"
                  textTransform="uppercase"
                  className={isDesktopView ? 'animation-heart' : ''}
                  onClick={handleClickDetail}
                />
              </WithAnimation>
            </Box>
          )}
        </Box>
      </Center>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
