import React from 'react';
import { Box, Center } from '@chakra-ui/react';

import CountDown from '@/components/Common/CountDown/V3';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import { IMG_PATTERN_SQUARE, BG_COUNTING_DOWN } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import { string } from 'prop-types';

import txt from './locales';
import useLang from '@hooks/useLang';

function CountingDown() {
  const lang = useLang();
  return (
    <Box
      zIndex="3"
      bgColor="bgPrimary"
      bgImage={`url(${IMG_PATTERN_SQUARE})`}
      bgSize="80%"
      backgroundRepeat="repeat"
      padding="62px 24px"
    >
      <Box height="100%">
        <WithAnimation left>
          <Box
            width="100%"
            height="580px"
            bgImage={`url(${BG_COUNTING_DOWN})`}
            bgSize="cover"
            bgPosition="bottom"
            borderRadius="16px"
            border="6px solid"
            borderColor="bgSecondary"
          >
            <Box paddingTop="60px">
              <CountDown lang={lang} />
              <WithAnimation>
                <Center>
                  <AddToCalendar
                    text={txt.detail[lang]}
                    size="sm"
                    fontSize="14px"
                    color="alternativeDark"
                    bgColor="bgSecondary"
                    boxShadow="xl"
                    {...BUTTON_PROPS}
                  />
                </Center>
              </WithAnimation>
            </Box>
          </Box>
        </WithAnimation>
      </Box>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;
