import React from 'react';
import { string } from 'prop-types';
import { Image, Text, Box, Center, Button, useDisclosure, AspectRatio } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import Content from './Content';

import { IMG_PHOTO_GALLERY, IMG_LOGO_INITIAL, IMG_PATTERN_SQUARE } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';

import txt from './locales';

function PhotoSection({ lang }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box padding="42px 24px" bgSize="cover" bgColor="bgPrimary">
      <Box>
        <WithAnimation>
          <Center>
            <Image src={IMG_LOGO_INITIAL} width="100%" maxW="100px" />
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Text
            margin="16px 0"
            fontSize="md"
            textAlign="center"
            color="mainColorText"
            fontFamily="bodyAlternative"
          >
            ...and so the adventure begins
          </Text>
        </WithAnimation>
      </Box>
      <WithAnimation>
        <Center maxHeight="400px">
          <AspectRatio margin="16px 0" width="100%" maxW="250px" ratio={3 / 4}>
            <Box
              bgImage={`url(${IMG_PATTERN_SQUARE})`}
              bgSize="contain"
              border="4px solid"
              borderColor="bgSecondary"
              borderRadius="16px"
              padding="24px"
            >
              <Image
                src={IMG_PHOTO_GALLERY}
                objectPosition="center"
                borderRadius="16px"
                margin="0 auto"
              />
            </Box>
          </AspectRatio>
        </Center>
      </WithAnimation>
      {/* Button Section */}
      <WithAnimation>
        <Center>
          <Button
            size="sm"
            margin="16px 0 32px 0"
            fontWeight="light"
            colorScheme="blackAlpha"
            bgColor="bgSecondary"
            color="alternativeDark"
            fontFamily="bodyAlternative"
            onClick={() => onOpen()}
            {...BUTTON_PROPS}
          >
            {txt.lihatGalery[lang]}
          </Button>
        </Center>
      </WithAnimation>
      {/* Content gallery */}
      <Content lang={lang} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);
